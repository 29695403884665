<template>
    <div class="main home">
        <div class="home-box">
            <h1>价格模板</h1>
            <div class="home-content">
                <!-- 查询条件 -->
                <search-item :searchInfo.sync="info" @searchList="searchList" @addTemplate="addTemplate"></search-item>
                <!-- 表格 -->
                <div class="table_container">
                    <el-table size="mini" border :data="tableData" stripe v-loading="tableLoading" style="width: 100%" align='center'>
                        <el-table-column prop="templateType" label="模板分类" align='center'>
                            <template slot-scope='scope'>
                                {{ scope.row.templateType == 1 ? '水费模板': '其他模板' }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="templateName" label="模板名称" align='center'>
                        </el-table-column>
                        <el-table-column prop="payType" label="付费模式" align='center'>
                            <template slot-scope='scope'>
                                {{ scope.row.chargeType == 1 ? '传统付费': '预付费' }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="pricingCycle" label="计价周期" align='center'>
                            <template slot-scope='scope'>
                                <span v-if="scope.row.pricingCycle == 1">按月</span>
                                <span v-if="scope.row.pricingCycle == 2">按季度</span>
                                <span v-if="scope.row.pricingCycle == 3">按年</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="chargePattern" label="计费模式" align='center'>
                            <template slot-scope='scope'>
                                <span v-if="scope.row.chargePattern == 1">统一定价(包月)</span>
                                <span v-if="scope.row.chargePattern == 2">定量(固定单价)</span>
                                <span v-if="scope.row.chargePattern == 3">阶梯</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="chargeAmount" label="计费金额" align='center'>
                        </el-table-column>
                        <el-table-column prop="chargeLadderAmount" label="阶梯计价" align='center'>
                        </el-table-column>
                        <el-table-column prop="status" label="状态" align='center'>
                            <template slot-scope='scope'>
                                {{ scope.row.status == 0 ? '启用': '停止' }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="createTime" label="创建时间" sortable align='center'>
                        </el-table-column>
                        <el-table-column prop="remark" label="备注" align='center'>
                        </el-table-column>
                        <!-- <el-table-column width="140px" fixed="right" label="操作" align='center'>
                                <template slot-scope='scope'>
                                    <el-button type="text" size="mini" @click='sendCommand(scope.row)'>编辑</el-button>
                                    <el-button type="text" size="mini" @click='modifyEnpr(scope.row)'>删除</el-button>
                                </template>
                            </el-table-column> -->
                    </el-table>
                    <pagination :paginationInfo="paginationInfo" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
                </div>
            </div>
        </div>
        <el-dialog :modal-append-to-body='false' title="新建/修改" :visible.sync="modifyEnprVisible" width="45%">
        <el-form ref="form" label-width="120px"  :inline="true">
            <el-form-item label="模板分类" required>
                <el-select v-model="templateType" placeholder="请选择模板分类" clearable >
                    <el-option v-for="item in xmflData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="模板名称" required>
                <el-input v-model="templateName" placeholder="请输入模板名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="计价模式" required>
                <el-select v-model="chargePattern" placeholder="请选择计费模式" clearable @change="chargePatternChange">
                    <el-option v-for="item in jfmsData" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="付费模式" required>
                <el-select v-model="payType" placeholder="请选择付费模式" clearable >
                    <el-option v-for="item in ffmsData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="计价周期" required>
                <el-select v-model="pricingCycle" filterable clearable placeholder="请选择计价周期">
                    <el-option v-for="item in jjzqData" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="周期数值" required>
                <el-input type="number" disabled v-model="zqsz" clearable></el-input>
            </el-form-item>
            <!-- <el-form-item label="状态" required>
                <el-select v-model="status" placeholder="请选择启用状态" clearable  >
                    <el-option v-for="item in qdztData" :key="item.id" :label="item.qdztname" :value="item.id"></el-option>
                </el-select>
            </el-form-item> -->
            <el-form-item label="备注">
                <el-input v-model="remark" clearable></el-input>
            </el-form-item>
            <el-form-item v-if="byshow" label="包月价格(元)" required>
                <el-input v-model="chargeAmount" clearable></el-input>
            </el-form-item>
            <el-form-item v-if="dlshow" label="固定单价(元/吨)" required>
                <el-input v-model="chargeAmount" clearable></el-input>
            </el-form-item>
            <el-form-item v-if="jtshow" label="阶梯" required>
                <span style="color:red;">请按照如下格式填写：起始值(吨)-结束值(吨)=单价(元/吨) 如 第一阶梯;第二阶梯 1-5=2.5;6-10=3</span><br>
                <el-input style="width:200px;" v-model="chargeAmount" clearable></el-input>
            </el-form-item>
            <el-form-item style="padding-left: 100px;">
                <el-button type="success" size="mini" plain :loading="commandLoading" @click="save">提交</el-button>
                <el-button size="mini" @click="closeEnprDialog">取消</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
    </div>
    </template>
    
    <script>
    import Pagination from "@/components/pagination";
    import SearchItem from "./searchItem";
    export default {
        data() {
            return {
                // 表格loading
                tableLoading: false,
                tableData: [], //表格数据
                paginationInfo: {
                    pageIndex: 1, // 当前位于哪页
                    pageTotal: 8, //表格总页数
                    pageSize: 10, //每页显示条数
                    pageSizes: [5, 10, 15, 20], //每页显示个数选择器的选项设置
                    layout: "total, sizes, prev, pager, next, jumper" // 翻页属性
                },
                pidList: [],
                yxqVisible: false,
                registerId: "",
                info: {
                    templateType: "",
                    templateName: "",
                    chargePattern: null,
                    payType: null,
                    status: ""
                },
                commandVisible: false,
                command: '0',
                modifyEnprVisible: false,
                nowEnpr: '',
                nowDeviceId: '',
                newEnpr: '',
                arrEnpr: [],
                selectedRow: '',
                enprLoading: false,
                commandLoading: false,
                templateType: "",
                templateName: "",
                chargePattern: "",
                pricingCycle: "",
                chargeAmount: null,
                zqsz: 1,
                status: "",
                remark: "",
                payType: "",
                byshow: false,
                dlshow: false,
                jtshow:false,
                xmflData:[
                {
                    name: "水费模板",
                    id: 1
                }
            ],
            jfmsData:[
                {
                    name: "统一定价（包月）",
                    id: 1
                },
                {
                    name: "定量（固定单价）",
                    id: 2
                },
                {
                    name: "阶梯",
                    id: 3
                }
            ],
            ffmsData:[
                {
                    name: "传统付费",
                    id: 1
                },
                {
                    name: "预付费",
                    id: 2
                }
            ],
            qdztData:[
                {
                    qdztname: "启动",
                    id: 1
                },
                {
                    qdztname: "停止",
                    id: 2
                }
            ],
            jjzqData: [
                {
                    name: "年",
                    id: 3
                },
                {
                    name: "季度",
                    id: 2
                },
                {
                    name: "月",
                    id: 1
                }
            ]
            }
        },
        mounted() {
            let that = this
            this.getJGTemplateList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
        },
        methods: {
            chargePatternChange(e){
                console.log(e)
                switch(e){
                    case 1:
                        this.byshow = true
                        this.dlshow = false
                        this.jtshow = false
                        break;
                        case 2:
                        this.byshow = false
                        this.dlshow = true
                        this.jtshow = false
                        break;
                        case 3:
                        this.byshow = false
                        this.dlshow = false
                        this.jtshow = true
                        break;
                        default:
                        this.byshow = false
                        this.dlshow = false
                        this.jtshow = false
                        break;
                }
            },
            //  获取表格信息
            getJGTemplateList(currpage, pageSize, info) {
                console.log(info)
                this.tableLoading = true;
                let params = {
                    pageNum: currpage,
                    pageSize: pageSize,
                    templateType: info.templateType,
                    templateName: info.templateName,
                    chargePattern: info.chargePattern,
                    payType: info.payType,
                    status: info.status
                }
                this.tableData = []
                this.$api.amountTemplateList(params).then(res => {
                    console.log('000', res.data.list)
                    this.tableLoading = false;
                    if (res && res.code == 200) {
                        this.paginationInfo.pageTotal = parseInt(res.data.total)
                        this.paginationInfo.pageIndex = res.data.pageNum
                        this.tableData = res.data.list
                    }
                }).catch(error => {
                    console.log(error)
                    this.tableLoading = false;
                })
            },
            searchList(e) {
                console.log(e)
                this.info = e
                this.getJGTemplateList(1, this.paginationInfo.pageSize, e)
            },
            // 上下分页
            handleCurrentChange(val) {
                // console.log('上下分页',val)
                let that = this
                this.paginationInfo.pageIndex = val
                this.getJGTemplateList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
            },
            // 每页显示多少条
            handleSizeChange(val) {
                // console.log('每页显示多少条',val)
                let that = this
                this.paginationInfo.pageSize = val
                this.getJGTemplateList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
            },
            addTemplate() {
                this.modifyEnprVisible = true
            },
            goEdit(e) {
                console.log(e)
                this.$router.push({
                    path: '/',
                    query: {
                        id: e.courseId
                    }
                })
            },
            submitDialog(e) {
                this.registerId = e.registerId
                this.centerDialogVisible = true
            },
            closeCommandDialog() {
                this.commandVisible = false
            },
            closeEnprDialog() {
                this.modifyEnprVisible = false
                this.newEnpr = ''
            },
            
            save() {
                let params = {
                    templateType: this.templateType,
                    templateName: this.templateName,
                    chargePattern: this.chargePattern,
                    payType: this.payType,
                    pricingCycle: this.pricingCycle,
                    chargeAmount: this.chargeAmount,
                    chargeLadderAmount: this.chargeLadderAmount,
                    remark:this.remark
                }
                this.commandLoading = true
                this.$api.savejgmbTemplate(params).then(res => {
                    if (res && res.code === 200) {
                        this.modifyEnprVisible = false
                        this.commandLoading = false
                        this.$message({
                            message: '添加价格项目成功',
                            type: 'success'
                        })
                        this.getJGTemplateList(this.paginationInfo.pageIndex, this.paginationInfo.pageSize, this.info)
                    } else {
                        this.commandLoading = false
                        this.$message({
                            message: res.msg,
                            type: 'error'
                        })
                    }
                })
            },
            //启用或停用设备
            stateControl(e) {
                let state = e === 0 ? '停用' : '启用'
                this.$confirm(`确定要${state}该设备吗?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
    
                })
            },
            del(e) {
                console.log(e)
                let that = this
                this.$confirm('确定要删除该设备吗?删除后将无法恢复，需重新注册', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$api.delDevice({
                        id: e.id
                    }).then(res => {
                        if (res.code === 0) {
                            this.getJGTemplateList(that.paginationInfo.pageIndex, that.paginationInfo.pageSize, that.info)
                            this.$message({
                                type: 'success',
                                message: '删除成功'
                            });
                        } else {
                            this.$message({
                                type: 'error',
                                message: '删除失败'
                            });
                        }
                    })
                }).catch(() => {});
            },
            //发送指令弹框
            sendCommand(e) {
                this.commandVisible = true
                this.selectedRow = e
            },
            //修改水司弹框
            modifyEnpr(e) {
                console.log(e)
                this.nowEnpr = e.enterpriseName
                this.nowDeviceId = e.id
                this.modifyEnprVisible = true
            }
        },
        components: {
            Pagination,
            SearchItem
        }
    }
    </script>
    
    <style>
    /* 标题 */
    .menu .title1 img {
        top: 22px;
    }
    
    .menu .title1 h3 {
        font-size: 20px;
        margin-top: -14px;
    }
    
    /* 分页 */
    .el-pagination button,
    .el-pagination span:not([class*=suffix]) {
        padding: 0 10px;
    }
    
    .home .block-title {
        width: 100%;
        padding: 0 0 28px 20px;
        display: flex;
        align-items: center;
    }
    
    .home .block-title h3 {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #2d3039;
    }
    
    .home .a-text {
        text-decoration: underline;
        color: #1e61ce;
    }
    
    .home .block-title .btn {
        width: 180px;
        height: 35px;
        background-color: #1e61ce;
        border-radius: 20px;
        margin-left: 20px;
        font-weight: normal;
        font-stretch: normal;
        color: #ffffff;
        padding: 0;
    }
    
    .home .btn span {
        font-size: 16px !important;
        color: #fff !important;
    }
    
    .home .block-title span {
        font-size: 20px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #63717A;
    }
    
    .home .top {
        width: 100%;
        padding: 0 0 10px 0;
        border-radius: 10px;
        background: #fefefe;
    }
    
    .home .top .title {
        width: 100%;
        height: 90px;
        line-height: 90px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        font-family: PingFangSC-Semibold;
        font-size: 22px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #2d3039;
        border-bottom: 1px solid #f2f3f5;
    }
    
    .home .el-col {
        border-radius: 10px;
    }
    
    .home .grid-content {
        border-radius: 10px;
        /* min-height: 36px; */
    }
    
    .home .leftInfo {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
    }
    
    .home .leftInfo .headPic {
        width: 165px;
        height: 165px;
        border-radius: 100%;
        border: 8px solid #fff;
        position: relative;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        box-shadow: 0px 12px 30px 0px rgba(6, 37, 117, 0.12);
    }
    
    .home .leftInfo .headPic img {
        width: 100%;
    
    }
    
    .home .rightInfo {
        margin-top: 10px;
        padding-right: 10px;
    }
    
    .home .rightInfo .name-text {
    
        margin-bottom: 20px;
        font-family: PingFangSC-Semibold;
        font-size: 26px;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: 0px;
        color: #2d3039;
    }
    
    .home .rightInfo .info-text img {
        width: 26px;
        height: 26px;
        margin-right: 20px;
    }
    
    .home .rightInfo .info-text {
        margin-bottom: 20px;
        font-family: PingFangSC-Medium;
        font-size: 18px;
        letter-spacing: 0px;
        color: #63717a;
        display: flex;
        /* justify-content: center; */
        align-items: center;
        text-align: left
    }
    
    .home .rightInfo .info-text h1 {
        font-size: 20px;
        font-weight: 600;
        font-family: PingFangSC-Medium;
        color: #2d3039;
        margin-left: 10px;
        display: block;
        font-size: 90.5%;
    }
    
    .home .middle {
        width: 100%;
        border-radius: 10px;
    }
    
    .home .middle .grid-content {
        background: #fff
    }
    
    .home .title1 {
        width: 100%;
        display: flex;
        padding: 0 20px 20px 20px;
        align-items: center;
        margin-top: -10px;
        border-bottom: 1px solid #f2f3f5;
    }
    
    .home .title1 h3 {
        font-size: 20px;
        margin-top: -10px;
    }
    
    .home .title1 img {
        position: relative;
        top: 18px;
        margin-right: 10px;
    }
    
    .home .ajs-Title {
        margin-left: 20px;
    }
    
    .home .ajs {
        margin-left: 20px;
        color: #0033cf;
    }
    
    .home .left-block {
        height: 200px;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px;
        /* border-right: 1px solid #d8d8d8;
            border-bottom: 1px solid #d8d8d8; */
    }
    
    .home .right-block {
        height: 200px;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid #d8d8d8;
    }
    
    .home .main-block {
        height: 200px;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid #d8d8d8;
    }
    
    /*案件办理天数 标签统计 hmm 090403*/
    .home .labelModule {
        background: #F2F5FA;
        border: 1px solid #D6E0F0;
        border-radius: 10px;
        display: inline-block;
        width: 100%;
        padding: 20px;
    }
    
    .home .labelModule:first-child {
        margin-bottom: 20px;
    }
    
    .home .labelModule ul li {
        width: 100%;
        font-size: 16px;
        color: #63717A;
        text-align: center
    }
    
    .home .labelModule ul li:first-child {
        margin-bottom: 10px;
    }
    
    .home .labelModule ul li font {
        font-size: 41px;
        color: #2D3039;
    }
    
    /*公共部分 hmm*/
    .p20 {
        padding: 20px;
    }
    
    .pb30 {
        padding-bottom: 30px;
    }
    
    /*办案类型 数据超出 显示样式 hmm 090404*/
    
    .widthBig .labelModule {
        width: 49.2%;
    }
    
    .widthBig .labelModule:first-child {
        margin-bottom: 30px;
        margin-right: 20px;
    }
    
    .widthSmall {
        float: right;
    }
    
    .openstate {
        color: #8CC663;
    }
    
    .stopstate {
        color: #e92d0c;
    }
    </style>
    