<template>
<div class="search_container searchArea">
    <el-form :inline="true" :model='searchInfo' ref="searchInfo" :rules="rules" :label-position="labelPosition" class="demo-form-inline search-form">
        <div class="search_form_item">
            <el-form-item prop="name" label="模板分类">
                <el-select v-model="searchInfo.templateType" placeholder="请选择模板分类" clearable  @change="templateTypeChange">
                    <el-option v-for="item in xmflData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="name" label="模板名称">
                <el-input v-model="searchInfo.templateName" placeholder="请输入模板名称" @change="templateNameChange" clearable></el-input>
            </el-form-item>
            <el-form-item prop="name" label="计费模式">
                <el-select v-model="searchInfo.chargePattern" placeholder="请选择计费模式" clearable  @change="chargePatternChange">
                    <el-option v-for="item in jfmsData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="name" label="付费模式">
                <el-select v-model="searchInfo.payType" placeholder="请选择付费模式" clearable @change="payTypeChange" >
                    <el-option v-for="item in ffmsData" :key="item.id" :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item prop="name" label="启用状态">
                <el-select v-model="searchInfo.status" placeholder="请选择启用状态" clearable  @change="statusChange" >
                    <el-option v-for="item in qdztData" :key="item.id" :label="item.qdztname" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item class="search-btn">
                <el-button type="success" size="mini" icon="el-icon-search" plain @click="searchList()">查询</el-button>
                <!-- <el-button size="mini" icon="el-icon-refresh-left" plain @click="addKC()">重置</el-button> -->
                <el-button type="success" size="mini" icon="el-icon-plus" plain @click="addKC()">新建</el-button>
            </el-form-item>
        </div>
    </el-form>
</div>
</template>

<script>
import {
    mapGetters
} from 'vuex'

export default {
    name: 'searchItem',
    props: {
        searchInfo: {
            type: Object,
            required: true
        }

    },
    data() {
        return {
            labelPosition: 'left',
            rules: {},
            xmflData:[
                {
                    name: "水费模板",
                    id: 1
                }
            ],
            jfmsData:[
                {
                    name: "统一定价（包月）",
                    id: 1
                },
                {
                    name: "定量（固定单价）",
                    id: 2
                },
                {
                    name: "阶梯",
                    id: 3
                }
            ],
            ffmsData:[
                {
                    name: "传统付费",
                    id: 1
                },
                {
                    name: "预付费",
                    id: 2
                }
            ],
            qdztData:[
                {
                    qdztname: "启动",
                    id: 1
                },
                {
                    qdztname: "停止",
                    id: 2
                }
            ]
        }
    },
    computed: {},
    created() {},
    methods: {
        // 查询
        searchList() {
            this.$emit('searchList', this.searchInfo)
        },
        addKC() {
            this.$emit('addTemplate')
        },
        //有效期设置
        yxqsz() {
            this.$emit('yxqSetting')
        },
        templateTypeChange(e){
            this.searchInfo.templateType = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        templateNameChange(e){
            this.searchInfo.templateName = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        chargePatternChange(e) {
            this.searchInfo.chargePattern = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        payTypeChange(e) {
            this.searchInfo.payType = e
            this.$emit('update:searchInfo', this.searchInfo)
        },
        statusChange(e) {
            this.searchInfo.status = e
            this.$emit('update:searchInfo', this.searchInfo)
        }
    }
}
</script>

<style>
.search_container {
    padding: 20px 0 0px;
    /* border-bottom: 1px solid #eff1f4; */
}
</style>
